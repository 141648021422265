import React from 'react';
import Layout from '../../components/Layout';
import ServicePage from '../../components/ServicePage';

function framing_and_drywall({location}) {
    return (
        <div>
            <Layout pageName={location.pathname}>
                <ServicePage serviceNumber={5}/>
            </Layout>
        </div>
    )
}

export default framing_and_drywall;
